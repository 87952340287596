import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Mailchimp from '../components/mailchimp'
import gravatar from 'gravatar'
import moment from 'moment'
import defaultCover from '../images/covers/blog-default.png'

const Post = ({ data }) => {
    const { markdownRemark } = data
    const { frontmatter, html, excerpt } = markdownRemark

    const coverImage = frontmatter.coverImage ? frontmatter.coverImage.publicURL : defaultCover

    return (
        <Layout 
          title={frontmatter.title}
          description={excerpt}
          cover={coverImage}
        >
            <div className="blog-post container mt-12">

              {/* intro */}
              <div className="flex items-center mb-12">
                <div className="flex-grow">
                  <h1 className="mb-4">{frontmatter.title}</h1>

                  <div className="text-sm text-gray-600 italic flex items-center">
                    <div className="w-8 mr-2">
                      <img 
                          src={gravatar.url(frontmatter.authorEmail || 'unknown-author@tail.dev', {d: 'mp'})} 
                          alt="Author avatar" 
                          className="inline-block w-4 rounded-full mr-1 w-full"
                      />
                    </div>
                    <span>{frontmatter.author} &middot; {frontmatter.date ? moment(frontmatter.date).format('MMMM Do YYYY') : '-'}</span>
                  </div>

                  {/* <h2>{frontmatter.date}</h2> */}
                </div>

                <div className="w-1/3">
                  <img src={coverImage} alt="Cover" className="w-full rounded shadow" />
                </div>
              </div>

              <div
                  className="blog-post-content bg-white mb-12 px-8 py-8 rounded shadow text-lg leading-7"
                  dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>

            <div className="text-center">
              <h4 className="mb-1">Get monthly updates</h4>
              <p className="text-gray-400">No bloat, just new platform features once a month.</p>
              <div style={{width: '350px'}} className="mx-auto">
                <Mailchimp />
              </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        date
        slug
        title
        author
        authorEmail
        coverImage {
          publicURL
        }
      }
    }
  }
`

export default Post